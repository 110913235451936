import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  satsAmountPlaceholder,
  receivePayment,
  lightningPaymentRequest,
  receive,
} from "../messages";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  satoshiLogo,
  btnIcQrCodeLogo,
} from "@speed/common/src/components/images";
import Button from "@speed/common/src/components/Button/Button";
import { Text } from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  maxSat,
} from "@speed/common/src/components/constants";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import ToastMessage from "../Common/ToastMessage";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as yup from "yup";
import { useFormik } from "formik";
import { callAPIInterfaceLnDev, minSat } from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { app } from "@speed/common/src/util/firebase";
import { sessionService } from "redux-react-session";
import SignInBox from "../../SignInBox";

const Receive = ({ setBalances }) => {
  const [focusAmount, setFocusAmount] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [loader, setLoader] = useState(false);
  const [receiveRequest, setReceiveRequest] = useState(false);
  const [signInBox, setSignInBox] = useState(false);
  const [receiveResponse, setReceiveResponse] = useState({});
  const [paymentReceive, setPaymentReceive] = useState({});

  const { lnReceiveFe } = useFlags();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const validationSchema = yup.object({
    sats_amount: yup.number().integer().required().min(minSat).max(maxSat),
  });

  const formik = useFormik({
    initialValues: {
      sats_amount: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (sessionStorage.getItem("satsAmount")) {
      setFieldValue("sats_amount", sessionStorage.getItem("satsAmount"));
      sessionStorage.removeItem("satsAmount");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (paymentReceive && Object.keys(paymentReceive).length)
      setReceiveResponse({});
  }, [paymentReceive]);

  const getReceiveRequest = async () => {
    setPaymentReceive({});
    setLoader(true);
    setReceiveResponse({});
    const data = {
      amount: values.sats_amount,
      currency: "SATS",
    };
    callAPIInterfaceLnDev("POST", "/payments", data)
      .then((response) => {
        if (response) {
          setReceiveResponse({
            amount: values.sats_amount,
            payment_request:
              response?.payment_method_options?.lightning?.payment_request ||
              response?.payment_request,
            currency: "SATS",
          });
          getFirebasePayment(response?.id);
          setReceiveRequest(true);
          setLoader(false);
        }
      })
      .catch((_e) => {
        if (_e?.response?.status === 403) {
          sessionStorage.setItem("satsAmount", values.sats_amount);
          setReceiveRequest(true);
          setLoader(false);
          setSignInBox(true);
        }
      });
  };

  const { values, setFieldValue, isValid, dirty } = formik;

  const getFirebasePayment = async (accountId) => {
    const session = await sessionService.loadSession();
    const db = getFirestore(app);
    const userRef = doc(
      db,
      "account",
      session.speed_acc_id,
      "payments",
      accountId
    );
    try {
      const unsubscribe = onSnapshot(userRef, async (querySnapshot) => {
        const data = querySnapshot.data();
        if (data && data.status === "PAID") {
          setPaymentReceive(data);
          setBalances(true);
          unsubscribe();
        }
      });
    } catch (err) {
      console.log(
        "Error while attaching listener to the Account firestore",
        err
      );
    }
  };

  return (
    <Box className={signInBox && "signIn-box"}>
      <Box className={`flex-display input-box ${focusAmount && "focused"}`}>
        <Box sx={{ width: "30px !important" }}>
          <img
            alt="no-data"
            src={satoshiLogo}
            className="satoshi-logo"
            style={{ padding: "10px 0 0 15px" }}
          />
        </Box>
        <Input
          fullWidth
          showLabel={false}
          placeholder={satsAmountPlaceholder}
          type="text"
          value={values.sats_amount}
          onChange={(e) => {
            setReceiveRequest(false);
            setReceiveResponse({});
            setPaymentReceive({});
            const { value } = e.target;
            !/[^\d]/.test(value) &&
              setFieldValue(
                "sats_amount",
                value.length > 0 ? parseInt(value) : value
              );
          }}
          onFocus={() => setFocusAmount(true)}
          onBlur={() => setFocusAmount(false)}
          className="lndev-input amount-box"
          disabled={lnReceiveFe}
        />
      </Box>
      {signInBox && (
        <SignInBox
          setReceiveRequest={setReceiveRequest}
          setSignInBox={setSignInBox}
          type={receive}
        />
      )}
      {!receiveRequest && (
        <Box className="lndev-payment-btn">
          <Button
            style={{ width: "190px" }}
            loading={loader}
            label={receivePayment}
            disabled={!(isValid && dirty)}
            onClick={() => getReceiveRequest()}
          />
        </Box>
      )}
      {receiveResponse && Object.keys(receiveResponse).length > 0 && (
        <Box sx={{ paddingTop: "30px" }}>
          <Box
            className={`lndev-receive-payment-request ${
              showQR ? "show-receive-QR" : "hide-receive-QR"
            }`}
          >
            <Box className="flex-display lndev-receive-payment lndev-receive-payment-label">
              <Box sx={{ width: "210px !important" }}>
                <Text
                  className="default-text"
                  font="semibold"
                  size={14}
                  variant="h4"
                >
                  {lightningPaymentRequest}
                </Text>
              </Box>
              <Box>
                <Text
                  font="semibold"
                  sx={{ color: "#2ecc71" }}
                  size={24}
                  variant="h4"
                  className="sats-amount-lndev-receive"
                >
                  {receiveResponse?.amount} {receiveResponse?.currency}
                </Text>
              </Box>
            </Box>
            <Box className="flex-display lndev-receive-payment lndev-receive-payment-link">
              <Box className="clipboard-link-receive">
                {clipboardElement(
                  receiveResponse?.payment_request,
                  true,
                  "payment-request",
                  "inputBox",
                  false
                )}
              </Box>
              <Box
                onClick={() => setShowQR(true)}
                className="scan-qr-code pointer-cursor"
              >
                <img
                  alt="no-data"
                  src={btnIcQrCodeLogo}
                  className="btnIcQrCode-logo"
                />
              </Box>
            </Box>
            {showQR && (
              <Box sx={{ paddingTop: "20px" }}>
                <QRCodeComponent
                  value={receiveResponse?.payment_request}
                  size={200}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      {paymentReceive && Object.keys(paymentReceive).length > 0 && (
        <ToastMessage
          tableClassName="receive-toast"
          text={receivePayment}
          icon={
            <CheckCircleIcon
              className="success-icon"
              sx={{ color: "#2ecc71" }}
            />
          }
          amountData={parseInt(paymentReceive.target_amount_paid) + " SATS"}
        />
      )}
    </Box>
  );
};

export default Receive;
