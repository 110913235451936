export const mobileNoPlaceholder = "Enter Mobile number";
export const mobileNumberValidate = (label) => `This is not a valid ${label}`;

// Global Search Bar Messages
export const tryAgainText = "Please try again with a different keyword";
export const noResultfound = "No results found";
export const searchPlaceholder = "Search";
export const showAllResult = "Show all result for";
export const suggestedFilterHeaderText = "Suggested Filters";
export const searchTipText = "Search Tips";

export const speed = "Speed";
export const somethingWrong =
  "Something went wrong. Please try again after some time";
export const Wallet = "Wallet";
export const Merchant = "Merchant";

export const needHelp = "Need help?";
export const loadingMsg = "Loading...";
export const loggingOutMsg = "Logging out...";

// Header Profile Menu Messages
export const profileText = "Profile";
export const logoutText = "Logout";

export const addDomainContentMsg =
  "Add these DNS records to your domain provider to verify that you own this domain. We'll email you to finish setting up.";
export const emptySubText = (mode, module, status) =>
  `No ${status || ""} ${mode ? "" : "test"} ${module}`;
export const signInMsg = "Sign in to your account";
export const signIndescriptionMsg =
  "It’s great to have you here again. Kindly provide your registered email address.";
export const invalidEmail = "Email address is invalid";

// Labels

export const emailLabel = "Email Address";
export const emailPlaceholder = "john@example.com";
export const passwordLabel = "Password";
export const passwordPlaceholder = "Enter password";
export const signIn = "Sign In";
export const signUp = "Sign Up";
export const back = "Back";
export const next = "Next";
export const skip = "Skip";
export const cancel = "Cancel";
export const amount = "Amount";
export const otherLabel = "Other";
export const radio = "radio";
export const checkbox = "checkbox";
export const initial = "initial";
export const pleaseSpecify = "Please specify";
export const inProgress = "in-progress";
export const accessDeniedText = "Access Denied";
export const notAuthorizedText =
  "You are not authorized to view this. Please contact the administrator or Speed Support.";
export const withdrawalLink = "withdrawal link";
export const emptyInvitedSubText =
  "There are no pending invitations available.";
export const errorMessage =
  "Your session has expired. Please login again to continue with the Speed";
export const lightningLabel = "Lightning";
export const onChainLabel = "On-chain";
export const lnUrlPay = "(LNurl Pay)";
export const BTC = "BTC";
export const phoneNumber = "Phone Number";
export const email = "Email";
export const submitAndGetQR = "Submit & Get QR";
export const address = "Address";
export const state = "State";
export const city = "City";
export const zipcode = "Zipcode";
export const contactInfo = "Contact information";
export const billingAddress = "Billing address";
export const name = "Name";
export const country = "Country";
export const shippingAddress = "Shipping address";
export const updateAndGetQR = "Update & Get QR";
export const edit = "Edit";
export const add = "Add";
export const details = "Details";
